@font-face {font-family: "Sketchnote Square";
    src: url("assets/fonts/Sketchnote Square Regular.eot"); /* IE9*/
    src: url("assets/fonts/Sketchnote Square Regular.woff2") format("woff2"), /* chrome firefox */
    url("assets/fonts/Sketchnote Square Regular.woff") format("woff"), /* chrome firefox */
    url("assets/fonts/Sketchnote Square Regular.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("assets/fonts/Sketchnote Square Regular.svg#Sketchnote Square") format("svg"); /* iOS 4.1- */
}
@font-face {font-family: "Franklin Gothic";
    src: url("assets/fonts/FranklinGothicURWComBoo W07 Rg.eot"); /* IE9*/
    src: url("assets/fonts/FranklinGothicURWComBoo W07 Rg.woff2") format("woff2"), /* chrome firefox */
    url("assets/fonts/FranklinGothicURWComBoo W07 Rg.woff") format("woff"), /* chrome firefox */
    url("assets/fonts/FranklinGothicURWComBoo W07 Rg.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("assets/fonts/FranklinGothicURWComBoo W07 Rg.svg#Franklin Gothic") format("svg"); /* iOS 4.1- */
}
@font-face {font-family: "Museo Sans";
    src: url("assets/fonts/Museo Sans 500.eot"); /* IE9*/
    src: url("assets/fonts/Museo Sans 500.woff2") format("woff2"), /* chrome firefox */
    url("assets/fonts/Museo Sans 500.ttf") format("truetype"); /* iOS 4.1- */
}
@font-face {font-family: "Museo Sans 700";
    src: url("assets/fonts/Museo Sans 700.eot"); /* IE9*/
    src: url("assets/fonts/Museo Sans 700.woff2") format("woff2"), /* chrome firefox */
    url("assets/fonts/Museo Sans 700.woff") format("woff"), /* chrome firefox */
    url("assets/fonts/Museo Sans 700.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("assets/fonts/Museo Sans 700.svg#Museo Sans 700") format("svg"); /* iOS 4.1- */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Sketchnote Square',
    'Franklin Gothic', 'Museo Sans' , sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
