.big-waves {
    position: absolute;
    left: 0;
    width: 100%;
    object-fit: cover;
    z-index: 2;
}

@media (min-width: 1073px) {
    .big-waves {
        height: 1650px;
        margin-top: -50px;
    }
}

@media (max-width: 1073px) {
    .big-waves {
        height: 2750px;
    }
}

@media (max-width: 900px) {
    .big-waves {
        height: 2250px;
    }
}

@media (max-width: 600px) {
    .big-waves {
        height: 2000px;
    }
}

.videos__description {
    color: white;
    width: 500px;
}

@media (max-width: 900px) {
    .videos__description {
        width: 360px;
    }
}

@media (max-width: 600px) {
    .videos__description {
        width: 300px;
    }
}

.arrow:hover {
    cursor: pointer;
}

.player {
    width: 500px;
    height: 282px;
}

@media (max-width: 900px) {
    .player {
        width: 360px;
        height: 203px;
    }
}

.jarki__image {
    width: 248px;
    height: 75px;
}

@media (max-width: 600px) {
    .jarki__image {
        width: 159px;
        height: 48px;
    }
}
