.subtitle--big {
    font-size: 32px;
    line-height: 36px;
    text-align: center;
}

.subtitle--bigger {
    font-size: 40px;
    line-height: 46px;
    text-align: center;
}

/*@media (max-width: 900px) {*/
/*    .subtitle--big {*/
/*        font-size: 42px;*/
/*    }*/
/*}*/

@media (max-width: 600px) {
    .subtitle--big {
        font-size: 26px;
    }

    .subtitle--bigger {
        font-size: 32px;
    }
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 330px;
    z-index: 2;
}

@media (min-width: 1650px) {
    .bg {
        height: auto;
    }
}

@media (max-width: 600px) {
    .bg {
        height: 130px;
    }
}

.subtitle-new {
    transform-origin: 0 0;
    transform: rotate(-90deg);
    text-align: center;
    color: white;
    text-transform: uppercase;
}
